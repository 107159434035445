import {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
} from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
} from 'firebase/auth';

axios.defaults.baseURL = 'https://mr.mekami.com:4443';

export const useResponse = ({
  url,
  initialValue = null,
  authRequired = true,
  method = 'GET',
  data = null,
  isPaginated = true,
  immediate = true,
}) => {
  const [targetUrl, setTargetUrl] = useState(url);
  const [response, setResponse] = useState(initialValue);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const [nextUrl, setNextUrl] = useState(undefined);

  const [triggered, setTriggered] = useState(immediate);
  const trigger = useCallback(() => {
    setTriggered(true);
  }, []);

  const [initialValueRef] = useState(initialValue);
  const resetResponse = useCallback(() => {
    setResponse((prevResponse) => initialValueRef);
  }, [initialValueRef]);

  const run = useCallback(
    async (source) => {
      if (authRequired && !auth.accessToken) {
        return;
      }

      const headers = {};
      if (auth.accessToken) {
        headers['Authorization'] = auth.accessToken;
      }

      setLoading(true);
      try {
        const res = await axios.request({
          url: targetUrl,
          method: method,
          headers: headers,
          data: data,
          cancelToken: source?.token,
        });
        if (isPaginated) {
          setResponse((prevResponse) => [
            ...(prevResponse || []),
            ...res.data.results,
          ]);
          setNextUrl(res.data.next);
        } else {
          setResponse(res.data);
        }
        setError(null);
      } catch (err) {
        setError(err);
        if (axios.isCancel(err)) {
          console.log('Canceled request');
        } else if (err?.response?.status !== 401) {
          console.log('Trowing');
          throw err;
        }
      } finally {
        setLoading(false);
      }
    },
    [targetUrl, method, data, isPaginated, auth.accessToken, authRequired]
  );

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (triggered) {
      run(source);
    }

    return () => {
      source.cancel();
    };
  }, [run, triggered]);

  useEffect(() => {
    resetResponse();
    setTargetUrl(url);
  }, [url, resetResponse]);

  const loadNextPage = useCallback(() => {
    if (nextUrl) {
      setTargetUrl(nextUrl);
    } else {
      trigger();
    }
  }, [nextUrl, trigger]);

  return { response, error, loading, loadNextPage, run, trigger };
};

export function useLocalStorage(key, initialValue) {
  // State to store our value Pass initial state function to useState so
  // logic is only executed once.
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  // https://github.com/uidotdev/usehooks/issues/76#issuecomment-639087236
  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue));
    } catch (error) {
      console.log(error);
    }
  }, [storedValue, key]);

  return [storedValue, setStoredValue];
}

initializeApp({
  authDomain: 'mekami-149321.firebaseapp.com',
  apiKey: 'AIzaSyBwnYxRlN-yW9Df9uM5kK-WTCStcWCetQI',
  appId:
    '310807708174-ok9m546ce91fjq0sesilj1mq392vtjnj.apps.googleusercontent.com',
});

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useLocalStorage('user', null);
  const [accessToken, setAccessToken] = useLocalStorage('accessToken', null);

  const signin = () => {
    const googleProvider = new GoogleAuthProvider();
    const auth = getAuth();
    return signInWithPopup(auth, googleProvider).then((response) => {
      setUser(response.user);
      setAccessToken(response.user.accessToken);
      return response.user;
    });
  };

  const signout = () => {
    const auth = getAuth();
    return signOut(auth).then(() => {
      setUser(null);
      setAccessToken(null);
    });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any component
  // that utilizes this hook to re-render with the latest auth object.
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setAccessToken(user.accessToken);
      } else {
        setUser(null);
        setAccessToken(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [setUser, setAccessToken]);

  return {
    user,
    accessToken,
    signin,
    signout,
  };
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useBackgroundQuery = () => {
  const location = useLocation();
  const search = location?.state?.background.search || '';

  return new URLSearchParams(search);
};

export const useInfiniteScroll = (loadNextPage) => {
  function handleScroll() {
    const currentPosition =
      window.innerHeight + document.documentElement.scrollTop;

    const milestone = document.documentElement.offsetHeight / 3;

    if (currentPosition >= milestone) {
      loadNextPage();
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
};
