import { useState, useEffect, useMemo, forwardRef } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  Link as RouterLink,
  useRouteMatch,
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';
import Slide from '@mui/material/Slide';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import AccountCircle from '@mui/icons-material/AccountCircle';
import BottomNavigation from '@mui/material/BottomNavigation';
import MuiBottomNavigationAction from '@mui/material/BottomNavigationAction';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import SaveIcon from '@mui/icons-material/Save';
import SvgIcon from '@mui/material/SvgIcon';
import InputBase from '@mui/material/InputBase';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import { CardActionArea } from '@mui/material';
import { grey, green, yellow } from '@mui/material/colors';
import { styled, alpha } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  useResponse,
  ProvideAuth,
  useAuth,
  useQuery,
  useInfiniteScroll,
  useBackgroundQuery,
} from './hooks';
import { usePlatforms } from './customHooks';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { slugify, filterFalsy } from './utils';
import logo from './logo.png';
import placeholder from './placeholder.jpg';

const ALL_PLATFORMS = 'all';

const theme = createTheme({
  palette: {
    primary: {
      main: '#e51c23',
      dark: '#d01716',
    },
    secondary: {
      main: '#2196F3',
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BottomNavigationAction = styled(MuiBottomNavigationAction)(
  ({ theme }) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&.Mui-selected': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.dark,
      fontSize: theme.typography.pxToRem(12),
    },
    '& .Mui-selected': {
      fontSize: theme.typography.pxToRem(12),
    },
  })
);

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(grey[500], 0.15),
  '&:hover': {
    backgroundColor: alpha(grey[500], 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

function LogoOffIcon(props) {
  return (
    <SvgIcon {...props}>
      <image
        id="image0"
        width="24"
        height="24"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAcCAQAAABbwC4kAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
cwAACxIAAAsSAdLdfvwAAAAHdElNRQflCR0KMBmThNZHAAABhklEQVRIx7XWsU7CUBSA4b936EYg
cWLybk4EVifqA5hgfABg8Y72DcA3cL2T9Q1KnGSxPABJjRtTWWQiKWFjcqCUNmKTSu+Zbpvc75yc
tCfHIg3doIckH/LXm+IIiPBVfHi0UnyMW6tLbGDBtpQJYHOFDaz4XuI++FaW1x79FteZIhal8Br3
2Ml5xTu7ofIABIDu5fE1Ucnat5kbTW7hWcuUZ1zL4W/sSjcnYJ6eL2jVcRNeS9ryTBxgTpCeJTiH
6iVp3/6PAyzSBE1oH5tTCZ5NsE6eBRDCohL8mCCCScKrmM8t00rwfYIpXxACWKAdPipx87FBqlgA
YwM41HFBaEnXCA8DEAwM4XCpO2L/+RuKnjDWGgBHnG8UhVm+a5aPzPJLQWy2+plBPhD4BnlfKM9Y
e173I21oBI9xk0VEvxRMnoiQkJAY2KhQ7/9yicShQ+PPezcqOO45oxNjOcBjpqKiEnWH/ondLuJO
hZDd0iSPdNIB5/FUDOeSOIxwgJiQGJ/JYQ38AQvXdWSCZUUpAAAAFXRFWHRDcmVhdGlvbiBUaW1l
ADE2LzEvMTWovNhVAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTA5LTI5VDEwOjQ4OjI1KzAxOjAw
WhCcSQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wOS0yOVQxMDo0ODoyNSswMTowMCtNJPUAAAAc
dEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAAAAElFTkSuQmCC"
      />
    </SvgIcon>
  );
}

const GameImage = ({ src, alt, sx = {} }) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const realImageProps = showPlaceholder ? { display: 'none' } : {};

  const imgProps = {
    component: 'img',
    alt: alt,
    loading: 'lazy',
  };

  return (
    <>
      {showPlaceholder && (
        <Box
          src={placeholder}
          srcSet={`${placeholder} 2x`}
          {...imgProps}
          sx={sx}
        />
      )}
      {src && (
        <Box
          src={src}
          srcSet={`${src} 2x`}
          onLoad={() => setShowPlaceholder(false)}
          {...imgProps}
          sx={{
            ...sx,
            ...realImageProps,
          }}
        />
      )}
    </>
  );
};

const RecommendationControls = ({ game }) => {
  const { run: toPlayRun } = useResponse({
    url: `/users/to-play/${game.id}/`,
    method: 'PUT',
    immediate: false,
  });

  const { run: discardRun } = useResponse({
    url: `/recommendations/blacklist/${game.id}/`,
    method: 'PUT',
    immediate: false,
  });

  const addToPlay = (event) => {
    event.preventDefault();
    toPlayRun();
  };

  const discard = (event) => {
    event.preventDefault();
    discardRun();
  };

  return (
    <ImageListItemBar
      position="below"
      sx={{ backgroundColor: 'black' }}
      title={
        <Stack
          direction="row"
          sx={{
            px: 5,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            sx={{ color: 'white' }}
            aria-label={`Add to play '${game.title}'`}
            onClick={addToPlay}
          >
            <SaveIcon />
          </IconButton>
          <IconButton
            sx={{ color: 'white' }}
            aria-label={`Discard '${game.title}'`}
            onClick={discard}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      }
    />
  );
};

const GameListItem = ({ game, showRecommendationControls = false }) => {
  const location = useLocation();

  return (
    <ImageListItem>
      <Link
        component={RouterLink}
        to={{
          pathname: `/games/${game.id}/${slugify(game.title)}/`,
          state: { background: location },
        }}
      >
        <GameImage
          src={game.front_boxart}
          alt={game.title}
          sx={{
            width: '100%',
            height: (t) => t.spacing(35),
            objectFit: 'cover',
          }}
        />
        <ImageListItemBar
          position="bottom"
          title={game.title}
          subtitle={game.platform.name}
          sx={{
            mb: showRecommendationControls ? 7 : undefined,
            '& > div > div': { whiteSpace: 'normal' },
          }}
        />
        {showRecommendationControls && <RecommendationControls game={game} />}
      </Link>
    </ImageListItem>
  );
};

const GameList = ({ games, showRecommendationControls = false }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(min(12rem, 100%), 1fr))',
        gap: 2,
      }}
    >
      {games.map((game) => {
        return (
          <GameListItem
            key={game.id}
            game={game}
            showRecommendationControls={showRecommendationControls}
          />
        );
      })}
    </Box>
  );
};

const SearchForm = ({ querySearch }) => {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState(querySearch || '');

  useEffect(() => {
    setSearchInput(querySearch || '');
  }, [querySearch]);

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const doSearch = () => {
    const uiQuery = new URLSearchParams(
      filterFalsy({
        search: searchInput,
      })
    );
    history.push({ search: uiQuery.toString() });
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={(event) => {
        event.preventDefault();
        document.activeElement.blur();
        doSearch(searchInput);
      }}
    >
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          value={searchInput}
          onChange={handleSearchChange}
        />
      </Search>
    </Box>
  );
};

const Loader = () => {
  return (
    <Box sx={{ textAlign: 'center', mt: 5 }}>
      <CircularProgress size={80} />
    </Box>
  );
};

const PlatformGamesList = ({ platformId, initialGames }) => {
  const endpointQuery = new URLSearchParams(
    filterFalsy({
      platform: platformId === ALL_PLATFORMS ? undefined : platformId,
      offset: initialGames.length,
    })
  );

  const {
    response: games,
    loading,
    loadNextPage,
  } = useResponse({
    url: `/games/?${endpointQuery}`,
    initialValue: initialGames,
    authRequired: false,
    immediate: false,
  });

  useInfiniteScroll(loadNextPage);

  return (
    <>
      <GameList games={games} />
      {loading && <Loader />}
    </>
  );
};

const PopularGamesTabs = ({ popularGames }) => {
  const platforms = usePlatforms();
  const [value, setValue] = useState(ALL_PLATFORMS);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sortedPlatforms = platforms.sort(
    (left, right) => left.weight - right.weight
  );
  const filteredPlatforms = sortedPlatforms.filter(
    (platform) => !popularGames[ALL_PLATFORMS] || popularGames[platform.id]
  );
  filteredPlatforms.unshift({ id: ALL_PLATFORMS, short_name: ALL_PLATFORMS });

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          aria-label="popular platforms menu"
        >
          {value &&
            filteredPlatforms.map((platform, idx) => {
              return (
                <Tab
                  key={platform.id}
                  value={platform.id.toString()}
                  label={platform.short_name}
                  {...a11yProps(idx + 1)}
                />
              );
            })}
        </Tabs>
      </Box>
      {Object.entries(popularGames).map(([platformId, games], idx) => {
        return (
          <TabPanel key={platformId} value={value} index={platformId}>
            <PlatformGamesList platformId={platformId} initialGames={games} />
          </TabPanel>
        );
      })}
    </>
  );
};

const SearchResultPage = ({ querySearch }) => {
  const endpointQuery = new URLSearchParams(
    filterFalsy({
      limit: 50,
      search: querySearch,
    })
  );

  const {
    response: games,
    loading,
    loadNextPage,
  } = useResponse({
    url: `/games/?${endpointQuery}`,
    initialValue: [],
    authRequired: false,
  });

  useInfiniteScroll(loadNextPage);

  return (
    <>
      <GameList games={games} />
      {loading && <Loader />}
    </>
  );
};

const PopularGamesPage = () => {
  const { response: popularGames, loading } = useResponse({
    url: '/games/populars/',
    initialValue: {},
    isPaginated: false,
  });

  return (
    <>
      <PopularGamesTabs popularGames={popularGames} />
      {loading && <Loader />}
    </>
  );
};

const GamesPage = () => {
  const auth = useAuth();
  const querySearch = useQuery().get('search');
  const backgroundSearch = useBackgroundQuery().get('search');

  const effectiveSearch = querySearch || backgroundSearch;

  return (
    <>
      <SearchForm querySearch={effectiveSearch} />

      <Container sx={{ mt: 5 }}>
        {((effectiveSearch || !auth.accessToken) && (
          <SearchResultPage querySearch={effectiveSearch} />
        )) || <PopularGamesPage />}
      </Container>
    </>
  );
};

const RecommendationsPage = () => {
  const {
    response: recommendations,
    loading,
    loadNextPage,
  } = useResponse({
    url: '/recommendations/',
    initialValue: [],
  });

  useInfiniteScroll(loadNextPage);

  return (
    <Container sx={{ mt: 5 }}>
      <GameList games={recommendations} showRecommendationControls />
      {loading && <Loader />}
    </Container>
  );
};

const GameTitle = ({ game }) => (
  <Typography component="span" sx={{ fontWeight: 'bold' }}>
    {game.title}
  </Typography>
);

const UserDisplayName = ({ user }) => (
  <Typography component="span" sx={{ fontWeight: 'bold' }}>
    {user.display_name}
  </Typography>
);

const Message = ({ action }) => {
  if (action.type === 'RECOMMENDS') {
    return (
      <>
        <UserDisplayName user={action.user} /> liked{' '}
        <GameTitle game={action.game} />
      </>
    );
  } else if (action.type === 'DISCOURAGES') {
    return (
      <>
        <UserDisplayName user={action.user} /> disliked{' '}
        <GameTitle game={action.game} />
      </>
    );
  } else if (action.type === 'TO_PLAY') {
    return (
      <>
        <UserDisplayName user={action.user} /> added{' '}
        <GameTitle game={action.game} /> into "To Play"
      </>
    );
  } else {
    return null;
  }
};

const ActionListItem = ({ action }) => {
  const location = useLocation();
  const message = Message({ action });

  if (!message) {
    return <></>;
  }

  return (
    <Card>
      <CardActionArea
        component={RouterLink}
        to={{
          pathname: `/users/${action.user.id}/${slugify(
            action.user.display_name
          )}/`,
          state: { background: location },
        }}
      >
        <CardMedia
          component="img"
          image={action.game.front_boxart}
          alt={action.game.title}
          sx={{ maxHeight: (t) => t.spacing(25) }}
        />
        <CardContent sx={{ p: 4 }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Avatar
              src={action.user.avatar}
              alt={action.user.display_name}
              sx={{
                width: (t) => t.spacing(9),
                height: (t) => t.spacing(9),
              }}
            />
            <Box sx={{ margin: 100 }} />
            <Typography variant="body1">{message}</Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const ActionList = ({ actions }) => {
  return (
    <Stack spacing={2}>
      {actions.map((action, idx) => (
        <ActionListItem key={idx} action={action} />
      ))}
    </Stack>
  );
};

const UserTab = ({ userId, endpoint }) => {
  const { response } = useResponse({
    url: `/users/${userId}/${endpoint}/`,
    initialValue: [],
  });

  const games = response.map((item) => (item.game ? item.game : item));

  return <GameList games={games} />;
};

const FeedPage = () => {
  const {
    response: actions,
    loading,
    loadNextPage,
  } = useResponse({
    url: '/feed/',
    initialValue: [],
  });

  useInfiniteScroll(loadNextPage);

  return (
    <Container sx={{ mt: 5 }}>
      <ActionList actions={actions} />
      {loading && <Loader />}
    </Container>
  );
};

const UserPageTabs = ({ user }) => {
  const { path, url } = useRouteMatch();
  const routeMatch = useRouteMatch([
    `${path}played/`,
    `${path}playing/`,
    `${path}to-play/`,
    `${path}liked/`,
    `${path}disliked/`,
  ]);

  const currentTab = routeMatch?.path ? routeMatch?.path : `${path}played/`;

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} aria-label="user profile menu">
          <Tab
            component={RouterLink}
            to={`${url}/played/`}
            value={`${path}played/`}
            label="Played"
            {...a11yProps(0)}
          />
          <Tab
            component={RouterLink}
            to={`${url}/playing/`}
            value={`${path}playing/`}
            label="Playing"
            {...a11yProps(1)}
          />
          <Tab
            component={RouterLink}
            to={`${url}/to-play/`}
            value={`${path}to-play/`}
            label="To Play"
            {...a11yProps(2)}
          />
          <Tab
            component={RouterLink}
            to={`${url}/liked/`}
            value={`${path}liked/`}
            label="Liked"
            {...a11yProps(3)}
          />
          <Tab
            component={RouterLink}
            to={`${url}/disliked/`}
            value={`${path}disliked/`}
            label="Disliked"
            {...a11yProps(4)}
          />
        </Tabs>
      </Box>
      <Switch>
        <Route exact path={`${path}played/`}>
          <TabPanel value={currentTab} index={`${path}played/`}>
            <UserTab userId={user.id} endpoint="played" />
          </TabPanel>
        </Route>
        <Route exact path={`${path}playing/`}>
          <TabPanel value={currentTab} index={`${path}playing/`}>
            <UserTab userId={user.id} endpoint="playing" />
          </TabPanel>
        </Route>
        <Route exact path={`${path}to-play/`}>
          <TabPanel value={currentTab} index={`${path}to-play/`}>
            <UserTab userId={user.id} endpoint="to-play" />
          </TabPanel>
        </Route>
        <Route exact path={`${path}liked/`}>
          <TabPanel value={currentTab} index={`${path}liked/`}>
            <UserTab userId={user.id} endpoint="recommend" />
          </TabPanel>
        </Route>
        <Route exact path={`${path}disliked/`}>
          <TabPanel value={currentTab} index={`${path}disliked/`}>
            <UserTab userId={user.id} endpoint="discourage" />
          </TabPanel>
        </Route>
        <Redirect to={`${url}/played/`} />
      </Switch>
    </>
  );
};

const UserProfilePage = () => {
  const auth = useAuth();
  const data = useMemo(
    () => ({
      token: auth.accessToken,
      email: auth.user?.email,
    }),
    [auth.accessToken, auth.user?.email]
  );

  const {
    response: me,
    loading,
    error,
  } = useResponse({
    url: '/users/',
    method: 'POST',
    isPaginated: false,
    data: data,
  });

  if (loading || error) {
    return <></>;
  }

  return (
    <>
      {me && (
        <>
          <User user={me} />
          <UserPageTabs user={me} />
        </>
      )}
    </>
  );
};

const Embed = ({
  variant,
  sx,
  ratio = 16 / 9,
  src,
  frameBorder = 0,
  allowFullScreen = true,
  height = 315,
  width = '100%',
  allow,
}) => {
  return (
    <Box variant={variant} sx={sx}>
      <Box
        as="iframe"
        src={src}
        width={width}
        height={height}
        frameBorder={frameBorder}
        allowFullScreen={allowFullScreen}
        allow={allow}
      />
    </Box>
  );
};

const makeEmbedded = (youtube_url) => {
  if (youtube_url.length === 11) {
    return `https://www.youtube.com/embed/${youtube_url}`;
  }
  if (youtube_url.startsWith('https://youtu.be/')) {
    return youtube_url.replace(
      'https://youtu.be/',
      'https://www.youtube.com/embed/'
    );
  }
  return youtube_url.replace('watch?v=', 'embed/');
};

const Game = ({ game }) => {
  const rates_avg = (100 * game.rates_avg).toFixed(2);
  const rates_color =
    rates_avg <= 50 ? 'red' : rates_avg <= 70 ? yellow[500] : green[500];

  const toKiloNotation = (number) => {
    const rounded = Math.round(number);
    return rounded >= 1000 ? `${Math.round(rounded / 1000)}k` : rounded;
  };

  const { run: recommendRun } = useResponse({
    url: `/users/recommend/${game.id}/`,
    method: 'PUT',
    immediate: false,
  });

  const { run: discourageRun } = useResponse({
    url: `/users/discourage/${game.id}/`,
    method: 'PUT',
    immediate: false,
  });

  const { run: deleteRecommendRun } = useResponse({
    url: `/users/recommend/${game.id}/`,
    method: 'DELETE',
    immediate: false,
  });

  const { run: deleteDiscouragedRun } = useResponse({
    url: `/users/discourage/${game.id}/`,
    method: 'DELETE',
    immediate: false,
  });

  const { run: toPlayRun } = useResponse({
    url: `/users/to-play/${game.id}/`,
    method: 'PUT',
    immediate: false,
  });

  const { run: deleteToPlayRun } = useResponse({
    url: `/users/to-play/${game.id}/`,
    method: 'DELETE',
    immediate: false,
  });

  const positives = game.rates_avg * game.rates_count;
  const negatives = (1 - game.rates_avg) * game.rates_count;

  const setUserRecommends = (newValue) => {
    const prevValue = game.user_recommends;

    const positivesDelta =
      newValue === true && (prevValue === null || prevValue === false)
        ? +1
        : prevValue === true
        ? -1
        : 0;

    const negativesDelta =
      newValue === false && (prevValue === null || prevValue === true)
        ? +1
        : prevValue === false
        ? -1
        : 0;

    const newPositives = positives + positivesDelta;
    const newNegatives = negatives + negativesDelta;

    game.rates_count = newPositives + newNegatives;
    game.rates_avg =
      game.rates_count === 0 ? 0 : newPositives / game.rates_count;
    game.user_recommends = newValue;
  };

  const recommend = () => {
    if (game.user_recommends === true) {
      deleteRecommendRun();
      setUserRecommends(null);
    } else {
      recommendRun();
      setUserRecommends(true);
    }
  };

  const discourage = () => {
    if (game.user_recommends === false) {
      deleteDiscouragedRun();
      setUserRecommends(null);
    } else {
      discourageRun();
      setUserRecommends(false);
    }
  };

  const addToPlay = () => {
    if (game.user_to_play === true) {
      deleteToPlayRun();
      game.user_to_play = false;
    } else {
      toPlayRun();
      game.user_to_play = true;
    }
  };

  return (
    <Box>
      <Embed src={makeEmbedded(game.youtube)} />

      <Stack direction="row" sx={{ mb: 5 }}>
        <GameImage
          src={game.front_boxart}
          alt={game.title}
          sx={{
            width: (t) => t.spacing(25),
            mr: 5,
          }}
        />
        <Stack>
          <Typography variant="h5">{game.title}</Typography>
          <Typography variant="h4" sx={{ color: rates_color }}>
            {rates_avg}
          </Typography>
          <Typography variant="h5">{game.platform.name}</Typography>

          <Typography variant="subtitle">
            Release date: {game.release_date}
          </Typography>
          <Typography variant="subtitle">
            Publisher: {game.publisher}
          </Typography>
          <Typography variant="subtitle">
            Developer: {game.developer}
          </Typography>

          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <IconButton
              aria-label="recommend"
              color={game.user_recommends === true ? 'primary' : 'default'}
              onClick={recommend}
            >
              <ThumbUpIcon
                sx={{
                  fontSize: 30,
                  color: 'primary',
                }}
              />
            </IconButton>

            <Typography variant="subtitle" sx={{ mr: 1 }}>
              {toKiloNotation(game.rates_count * game.rates_avg)}
            </Typography>

            <IconButton
              aria-label="discourage"
              color={game.user_recommends === false ? 'primary' : 'default'}
              onClick={discourage}
            >
              <ThumbDownIcon
                sx={{
                  fontSize: 30,
                }}
              />
            </IconButton>
            <Typography variant="subtitle">
              {toKiloNotation(game.rates_count * (1 - game.rates_avg))}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <IconButton
        aria-label="add to play"
        color={game.user_to_play === true ? 'primary' : 'default'}
        onClick={addToPlay}
      >
        <SaveIcon
          sx={{
            fontSize: 30,
            color: 'primary',
          }}
        />
      </IconButton>

      <ImageList
        sx={{
          gridAutoFlow: 'column',
          gridTemplateColumns: 'repeat(auto-fill,minmax(160px,1fr)) !important',
          gridAutoColumns: 'minmax(160px, 1fr)',
        }}
      >
        {game.images.map((image) => (
          <ImageListItem key={image}>
            <GameImage
              src={image}
              alt={game.title}
              sx={{
                height: (t) => t.spacing(35),
                objectFit: 'cover',
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>

      <Typography
        variant="body"
        sx={{
          fontWeight: 'light',
          whiteSpace: 'pre-wrap',
        }}
      >
        {game.overview}
      </Typography>
    </Box>
  );
};

const User = ({ user }) => {
  return (
    <>
      <Typography variant="h3" sx={{ mb: 3 }}>
        {user.display_name}
      </Typography>

      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Avatar
            src={user.avatar}
            alt={user.display_name}
            sx={{
              width: (t) => t.spacing(20),
              height: (t) => t.spacing(20),
            }}
          />

          <Stack sx={{ ml: 5 }}>
            <Typography variant="body1">{`${user.to_play_count} saved`}</Typography>
            <Typography variant="body1">{`${user.recommended_count} liked`}</Typography>
            <Typography variant="body1">{`${user.discouraged_count} disliked`}</Typography>
          </Stack>
        </Stack>

        <Stack direction="row">
          <Typography variant="body1">{`${user.following_count} following`}</Typography>
          <Typography
            variant="body1"
            sx={{ ml: 5 }}
          >{`${user.followers_count} followers`}</Typography>
        </Stack>
      </Stack>
    </>
  );
};

const UserPage = () => {
  const { id } = useParams();

  const {
    response: user,
    loading,
    error,
  } = useResponse({
    url: `/users/${id}/`,
    isPaginated: false,
    authRequired: false,
  });

  const history = useHistory();
  const location = useLocation();

  const close = () => {
    const target = location?.state?.background.pathname || '/';
    history.push(target);
  };

  return (
    <Dialog onClose={close} open={true} maxWidth="xl" fullWidth>
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          ml: 'auto',
          mt: 5,
          mr: 5,
        }}
      >
        <CloseIcon sx={{ fontSize: (t) => t.spacing(5) }} />
      </IconButton>
      <Box sx={{ px: 10, pb: 10 }}>
        {!loading && !error && user && <User user={user} />}
        {loading && <Loader />}
      </Box>
    </Dialog>
  );
};

const GamePage = () => {
  const { id } = useParams();

  const {
    response: game,
    loading,
    error,
  } = useResponse({
    url: `/games/${id}/`,
    isPaginated: false,
    authRequired: false,
  });

  const history = useHistory();
  const location = useLocation();

  const close = () => {
    const pathname = location?.state?.background.pathname || '/';
    const search = location?.state?.background.search || '';
    history.push(`${pathname}${search}`);
  };

  return (
    <Dialog onClose={close} open={true} maxWidth="xl" fullWidth>
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          ml: 'auto',
          mt: 5,
          mr: 5,
        }}
      >
        <CloseIcon sx={{ fontSize: (t) => t.spacing(5) }} />
      </IconButton>
      <Box sx={{ px: 10, pb: 10 }}>
        {!loading && !error && game && <Game game={game} />}
        {loading && <Loader />}
      </Box>
    </Dialog>
  );
};

const MainTabs = () => {
  const routeMatch = useRouteMatch(['/home/', '/for-you/', '/user/', '/']);
  const gamePageMatch = useRouteMatch(['/games/:id/:title/']);
  const userPageMatch = useRouteMatch(['/users/:id/:displayName/']);
  const location = useLocation();
  const background = location?.state?.background.pathname;

  const currentTab =
    gamePageMatch || userPageMatch ? background || '/' : routeMatch?.path;

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: { xs: 'none', lg: 'block' },
        }}
      >
        <Tabs value={currentTab} aria-label="main menu" centered>
          <Tab
            component={RouterLink}
            to="/"
            value="/"
            icon={<SearchIcon />}
            label="Games"
            {...a11yProps(0)}
          />
          <Tab
            component={RouterLink}
            to="/home/"
            value="/home/"
            icon={<HomeIcon />}
            label="Home"
            {...a11yProps(1)}
          />
          <Tab
            component={RouterLink}
            to="/for-you/"
            value="/for-you/"
            icon={<LogoOffIcon />}
            label="For You"
            {...a11yProps(2)}
          />
          <Tab
            component={RouterLink}
            to="/user/"
            value="/user/"
            icon={<AccountCircle />}
            label="User"
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <Switch>
        {((!background && currentTab === '/home/') ||
          background === '/home/') && (
          <Route path="*">
            <TabPanel value={currentTab} index="/home/">
              <FeedPage />
            </TabPanel>
          </Route>
        )}
        <Route path="/user/">
          <TabPanel value={currentTab} index="/user/">
            <UserProfilePage />
          </TabPanel>
        </Route>
        {((!background && currentTab === '/for-you/') ||
          background === '/for-you/') && (
          <Route exact path="*">
            <TabPanel value={currentTab} index="/for-you/">
              <RecommendationsPage />
            </TabPanel>
          </Route>
        )}
        {((!background && currentTab === '/') || background === '/') && (
          <Route exact path="*">
            <TabPanel value={currentTab} index="/">
              <GamesPage />
            </TabPanel>
          </Route>
        )}
      </Switch>
      <Route path="/games/:id/:title/">
        <GamePage />
      </Route>
      <Route path="/users/:id/:displayName/">
        <UserPage />
      </Route>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          display: { xs: 'block', lg: 'none' },
        }}
        elevation={3}
      >
        <BottomNavigation showLabels value={currentTab}>
          <BottomNavigationAction
            component={RouterLink}
            to="/"
            value="/"
            variant="contained"
            label="Games"
            icon={<SearchIcon />}
          />
          <BottomNavigationAction
            component={RouterLink}
            to="/home/"
            value="/home/"
            variant="contained"
            label="Home"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            component={RouterLink}
            to="/for-you/"
            value="/for-you/"
            variant="contained"
            label="For You"
            icon={<LogoOffIcon />}
          />
          <BottomNavigationAction
            component={RouterLink}
            to="/user/"
            value="/user/"
            variant="contained"
            label="User"
            icon={<AccountCircle />}
          />
        </BottomNavigation>
      </Box>
    </>
  );
};

const Logo = ({ sx }) => (
  <Box component="img" src={logo} alt="Mekami" sx={sx} />
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LoginDialog({ onClick }) {
  const auth = useAuth();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    onClick();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGoogleSignin = async () => {
    await auth.signin();
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>Login</MenuItem>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            height: '100%',
            background: (t) =>
              `radial-gradient(ellipse at center, #f69988 0%, ${t.palette.primary.main} 100%)`,
          }}
        >
          <IconButton
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 0,
              color: 'white',
              ml: 'auto',
              mt: 5,
              mr: 5,
            }}
          >
            <CloseIcon sx={{ fontSize: (t) => t.spacing(5) }} />
          </IconButton>
          <Container
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxWidth: (t) => ({
                xs: '90%',
                sm: '50%',
                md: '25%',
              }),
            }}
          >
            <Logo sx={{ maxWidth: '100%', mb: 3 }} />
            <GoogleLoginButton onClick={handleGoogleSignin} align="center">
              Sign in with Google
            </GoogleLoginButton>
          </Container>
        </Box>
      </Dialog>
    </div>
  );
}

function App() {
  return (
    <ProvideAuth>
      <InnerApp />
    </ProvideAuth>
  );
}

function InnerApp() {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const auth = useAuth();

  const signout = async () => {
    await auth.signout();
    handleClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Mekami
            </Typography>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {!auth.accessToken && <LoginDialog onClick={handleClose} />}
                {auth.accessToken && (
                  <MenuItem onClick={signout}>Logout</MenuItem>
                )}
              </Menu>
            </div>
          </Toolbar>
        </AppBar>

        <Container component="main">
          <Toolbar />

          <Router>
            <MainTabs />
          </Router>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
