export function slugify(string) {
  if (!string) {
    return string;
  }

  return string
    .normalize('NFKD')
    .replace(/[^\w\s-]/g, '')
    .trim()
    .toLowerCase()
    .replace(/[-\s]+/g, '-');
}

export const filterFalsy = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([k, v]) => v));
