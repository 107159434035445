import { useEffect } from 'react';
import { useResponse, useLocalStorage } from './hooks';

export const usePlatforms = () => {
  const ttl = 60;

  const { response, trigger } = useResponse({
    url: '/platforms/',
    initialValue: [],
    authRequired: false,
    immediate: false,
  });

  const [stored, setStored] = useLocalStorage('platforms', null);

  useEffect(() => {
    if (response.length <= 0) {
      return;
    }

    const now = Date.now();

    const valueTTL = {
      value: response,
      timestamp: now,
      ttl: ttl * 1000,
    };

    setStored(valueTTL);
  }, [response, setStored]);

  useEffect(() => {
    if (stored === null || Date.now() - stored?.timestamp >= stored?.ttl) {
      trigger();
    }
  }, [trigger, stored]);

  return stored?.value || [];
};
